import * as bootstrap from 'bootstrap';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import AOS from 'aos';
import img1 from "../src/assets/img/delazur-index_mini.png";
import img2 from "../src/assets/img/projet-1_mini.png";
import img3 from "../src/assets/img/projet-2_mini.png";
import img4 from "../src/assets/img/projet-4_mini.png";


  const text1_options = [
    "Projet: Delazur ",
    "Projet: Stereo",
    "Projet: Boutique",
    "Projet: Meteo ",
  ];
  const text2_options = [
    "Site Web du groupe de musique",
    "Site Web de style landing page",
    "Site Web de style vitrine avec panier d'achat",
    "Application météo utilisant l'API Open Meteo",
  ];
  const color_options = ["#F8EFE1", "#E8DEF1", "#FFE5D9", "#BDCBD2"];
  const image_options = [
    img1,
    img2,
    img3,
    img4
  ];
  let i = 0;
  const currentOptionText1 = document.getElementById("current-option-text1");
  const currentOptionText2 = document.getElementById("current-option-text2");
  const currentOptionImage = document.getElementById("image");
  const carousel = document.getElementById("carousel-wrapper");
  const mainMenu = document.getElementById("menu");
  const optionPrevious = document.getElementById("previous-option");
  const optionNext = document.getElementById("next-option");
  
  currentOptionText1.innerText = text1_options[i];
  currentOptionText2.innerText = text2_options[i];
  currentOptionImage.style.backgroundImage = "url(" + image_options[i] + ")";
  mainMenu.style.background = color_options[i];
  
  optionNext.onclick = function () {
    i = i + 1;
    i = i % text1_options.length;
    currentOptionText1.dataset.nextText = text1_options[i];
  
    currentOptionText2.dataset.nextText = text2_options[i];
  
    mainMenu.style.background = color_options[i];
    carousel.classList.add("anim-next");
    
    setTimeout(() => {
      currentOptionImage.style.backgroundImage = "url(" + image_options[i] + ")";
    }, 455);
    
    setTimeout(() => {
      currentOptionText1.innerText = text1_options[i];
      currentOptionText2.innerText = text2_options[i];
      carousel.classList.remove("anim-next");
    }, 650);
  };
  
  optionPrevious.onclick = function () {
    if (i === 0) {
      i = text1_options.length;
    }
    i = i - 1;
    currentOptionText1.dataset.previousText = text1_options[i];
  
    currentOptionText2.dataset.previousText = text2_options[i];
  
    mainMenu.style.background = color_options[i];
    carousel.classList.add("anim-previous");
  
    setTimeout(() => {
      currentOptionImage.style.backgroundImage = "url(" + image_options[i] + ")";
    }, 455);
    
    setTimeout(() => {
      currentOptionText1.innerText = text1_options[i];
      currentOptionText2.innerText = text2_options[i];
      carousel.classList.remove("anim-previous");
    }, 650);
  };
  
AOS.init();
